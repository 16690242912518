@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  display: flex;
  height: 100%;
  width: 100%;
}
.container {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
}
.main-container {
  max-width: 1500px;
  margin: 0 auto;
}
.cover {
  background-image: url('./img/cover.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;  
}
.katalog-ostalo {
  background-image: url('./img/korice.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;  
}
.katalog-rokovnici {
  background-image: url('./img/rokovnici.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;  
}
.phone-scroll {
  -webkit-overflow-scrolling: touch;
}
.grid-container {
  display: grid;
  grid-row-gap: 50px;
  grid-column-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
@media only screen and (min-width: 800px) {
  .model-card {
    max-width: 350px;
  }
}
.flex-0 {
  flex: 0 0 auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ea5a0c; 
  border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ea5a0c; 
}
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
input::placeholder {
  font-weight: 500;
  color: #a1a1aa;
}
textarea::placeholder {
  font-weight: 500;
  color: #a1a1aa;
}

.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #8cc759;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}
.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
